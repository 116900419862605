import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "provider-support-and-help-desk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#provider-support-and-help-desk",
        "aria-label": "provider support and help desk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Provider support and help desk`}</h1>
    <p>{`The Transaction SDK includes a link and live chat support module for provider inquiries. Providers can initiate a contextual help session right from the self-contained UI. We also provide links to online help articles and videos for all  claiming and payment scenarios.`}</p>
    <p>{`The support module links to our help system which identifies the business and status of transactions, including recently errored claims. This means that claim and payment transaction issues and inquiries can be easily directed to us rather than your help desk.`}</p>
    <p>{`The support module can be invoked anytime by clicking on the chat & help link. You can provide a link to our support module in your support system for  “ticket escalation” and other inquiries that require our attention.`}</p>
    <p>{`You can choose to hide the Medipass live chat and support module by setting the parameter `}<a parentName="p" {...{
        "href": "/sdk/getting-started/optional-settings/"
      }}><inlineCode parentName="a">{`hideChatBubble`}</inlineCode></a>{`. Only enable this option if you prefer for end user transaction queries to be directed to your help desk.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/sdk-support.png",
        "alt": null
      }}></img></p>
    <h3 {...{
      "id": "disabling-the-help-desk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#disabling-the-help-desk",
        "aria-label": "disabling the help desk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Disabling the help desk`}</h3>
    <p>{`If you'd rather handle the support on your platform, you can disable the help desk by `}<a parentName="p" {...{
        "href": "/sdk/sdk-reference/views/#options"
      }}>{`setting `}<inlineCode parentName="a">{`hideChatBubble: true`}</inlineCode>{` in options argument of the SDK`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  ...
}, {
  hideChatBubble: true
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      